// Navigation.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// import { useDispatch, useSelector } from "react-redux";
// import { createKey, setKeyFromLocalStorage } from "./features/apiSlice";

const Footer = () => {
  // const dispatch = useDispatch();
  // const { status, data } = useSelector((state) => state.api);

  // useEffect(() => {
  //   const savedKey = localStorage.getItem("apiKey");
  //   if (savedKey) {
  //     dispatch(setKeyFromLocalStorage(savedKey));
  //   } else {
  //     dispatch(createKey());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   if (status === "succeeded" && data && data.data) {
  //     localStorage.setItem("apiKey", data.data);
  //   }
  // }, [status, data]);

  const options = [
    { id: 1, name: "India", url: "https://partner.joboy.in" },
    { id: 2, name: "United Arab Emirates", url: "https://partner.joboy.ae" },
    { id: 3, name: "Canada", url: "https://partner.joboy.ca" },
    { id: 4, name: "United Kingdom", url: "https://partner.joboy.uk" },
    { id: 5, name: "Azerbaijan", url: "https://partner.joboy.az" },
    // {
    //   id: 6,
    //   name: "United States of America",
    //   url: "https://partner.joboy.us",
    // },
    { id: 7, name: "South Africa", url: "https://partner.joboy.co" },
  ];

  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getLink = () => {
    const selected = options.find(
      (option) => option.id === Number(selectedOption)
    );
    if (selected) {
      window.location.href = selected.url;
    }
  };

  const [countries, setCountries] = useState([]);
  //const [loading, setLoading] = useState(true);
  //const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        // const apiKey =
        //   data && data.data ? data.data : localStorage.getItem("apiKey");

        // if (!apiKey) {
        //   throw new Error("API key is missing");
        // }

        const response = await fetch("https://testjoboyapi.work.gd/country/list", {
          method: "GET",
          headers: {
            Authorization: "Basic YWRtaW46MTIzNA==",
            session: "9FJ87Z4N",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        if (result.status === "success") {
          setCountries(result.data);
        } else {
          throw new Error("Failed to fetch countries");
        }
      } catch (error) {
        //  setError(error);
      } finally {
        // setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return (
    <div>
      <section className="dark-gray-bg pt15">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-5">
              <h4 className="white-font">Are you a service expert?</h4>
              <p className="yellow-font">
                Join worlds Largest Service Network!
              </p>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="form-group select">
                <select
                  className="form-ctrl-p form-control"
                  id="link_select"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <option value="0">SELECT YOUR COUNTRY</option>
                     <option value="1">India</option>
                    <option value="2">United Arab Emirates</option>
                    <option value="3">Canada</option>
                    <option value="4">United Kingdom</option>
                    <option value="5">Azerbaijan</option>
                    <option value="7">South Africa</option>
                  {/* {options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))} */}
                </select>
                
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <button
                  className="btn book-now-btn btn-block"
                  onClick={getLink}
                  disabled={!selectedOption}
                  type="submit"
                >
                  Register as a Partner
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-area pt40 pb15">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget">
                <a
                  _ngcontent-vui-c24=""
                  routerlink="/"
                  className="logo"
                  href="/"
                >
                  {" "}
                </a>
                <p>
                  It is our mission to transform the unorganised services sector
                  through the use of technology, by helping customers connect
                  with verified professionals around them, and helping those
                  experts find customers with minimum investment
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget pl-5">
                <h3>Our Locations</h3>
                <ul className="list">
                  {/* {countries.map((country, index) => (
                    <li key={country.id || country.name}>
                      <a href={country.url}>{country.name}</a>
                    </li>
                  ))} */}

                  <li><a href="https://joboy.in">India</a></li>
                  <li><a href="https://joboy.ae">United Arab Emirates</a></li>
                  <li><a href="https://joboy.ca">Canada</a></li>
                  <li><a href="https://joboy.uk">United Kingdom</a></li>
                  <li><a href="https://joboy.az">Azerbaijan</a></li>
                  {/* <li><a href="https://joboy.us">United States of America</a></li>  */}
                  <li><a href="https://joboy.co">South Africa</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget">
                <h3>About Joboy</h3>
                <ul className="list">
                  <li>
                    <Link to="/about">Our Mission</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                  <li>
                    <a href="https://in.linkedin.com/company/joboy">
                      Connect With Us on LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget">
                <h3>Download App</h3>
                <div className="form-group">
                  
                  <a
                    href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460"
                    className="btn btn-market btn--with-shadow"
                  >
                    
                    <img
                      className="utouch-icon"
                      src="../joboyimages/ios.png"
                      alt="Joboy on Apple App Store"
                    />
                    <div className="text">
                    
                      <span className="sup-title">Download on the</span>{" "}
                      <span className="title">App Store</span>{" "}
                    </div>
                  </a>
                </div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.serville.joboy"
                  className="btn btn-market btn--with-shadow"
                >
                  
                  <img
                    className="utouch-icon"
                    src="../joboyimages/google-play.svg"
                    alt="Joboy on Google Play Store"
                  />
                  <div className="text">
                    
                    <span className="sup-title">Download on the</span>{" "}
                    <span className="title">Google Play</span>{" "}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-right">
          <div className="container">
            <div className="copy-right-content">
              <p>
                Copyright (2448/2016-CO/SW)@2022, Joboy Services Private Limited
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
